import { PROPERTY } from 'constants/tracking'

import trackingClient from '@typeform/js-tracking'
import {
  hasFunctionalConsent,
  hasPerformanceConsent,
} from '@typeform/js-tracking/lib/consents/consentUtil'
import { snakeCase } from '@s-libs/micro-dash'
import { NAVIGATION_LINKS_KEYS } from '@typeform/ginger/dist/constants/navigation'
import { SEGMENT } from '@typeform/js-tracking/lib/constants'
import attributionUtil from '@typeform/js-tracking/lib/attributionUtil'
import { getPlanNameFromPlanCode } from 'utils/pricing/plans'
import { trackCjAffiliatesSignup } from 'services/cj-affiliates'

import {
  addMiddlewares,
  getPage,
  getMandatoryProperties,
  getLinkUrl,
  setClearbitCookie,
  getAttributionUserId,
} from './helpers'

const { LANGUAGE_CODES } = require('@typeform/ginger/dist/lib/i18n')

export const ITEM_CLICKED = 'item_clicked'

export const initTracking = (trackingPayload = {}) => {
  const { trackingKey, COOKIE_DOMAIN, GTM_ID } = trackingPayload
  if (
    trackingClient.isInitialized(SEGMENT) ||
    !trackingKey ||
    !hasFunctionalConsent()
  ) {
    return
  }

  // Set the attribution_user_id cookie explicitly here.
  // There must always be a querystring value of attribute_user_id in the URL.
  const attributionUserId = getAttributionUserId()
  attributionUtil.generateUser(COOKIE_DOMAIN, attributionUserId)

  const options = {
    integrations: {
      'All': true,
      'Amplitude': hasPerformanceConsent(),
      'Actions Amplitude': hasPerformanceConsent(),
    },
  }

  const { mandatoryProperties = getMandatoryProperties() } = trackingPayload

  trackingClient.init(trackingKey, mandatoryProperties, options, GTM_ID)

  if (!window.analytics) return

  addMiddlewares()

  window.analytics.ready(() => {
    setClearbitCookie()
  })
}

export const trackTmpItemClicked = ({
  item,
  testId = 'all',
  ...otherProps
}) => {
  if (!item) {
    throw new Error('trackTmpItemClicked: missing required property `item`.')
  }

  trackingClient.trackTmpItemClicked({
    ...getMandatoryProperties(),
    item,
    test_id: testId,
    ...otherProps,
  })
}

/**
 * @type {import('./utils.types').TTrackExperimentEvent}
 **/
export const trackExperimentEvent = ({
  name,
  testId = 'all',
  payload = {},
  properties = {},
}) => {
  if (!name) {
    throw new Error('trackExperimentEvent: missing required property `name`.')
  }

  const resultProperties = {
    ...getMandatoryProperties(),
    test_id: testId,
    item: name,
    ...properties,
  }

  if (Object.keys(payload).length) {
    resultProperties.payload = payload
  }

  trackingClient.trackTmpItemClicked(resultProperties)
}

export const trackEvent = (eventName, properties = {}, options, callback) => {
  trackingClient.sendEvent(
    eventName,
    {
      ...getMandatoryProperties(),
      ...properties,
    },
    options,
    callback
  )
}

export const trackClickEvent = (properties, options, callback) => {
  trackEvent(ITEM_CLICKED, properties, options, callback)
}

export const trackViewPageSection = trackingData => {
  trackingClient.trackViewPageSection({
    ...getMandatoryProperties(),
    ...trackingData,
  })
}
export const trackSearchQueryEntered = trackingData => {
  trackingClient.trackSearchQueryEntered({
    ...getMandatoryProperties(),
    ...trackingData,
  })
}

export const trackSearchQueryRemoved = trackingData => {
  trackingClient.trackSearchQueryRemoved({
    ...getMandatoryProperties(),
    ...trackingData,
  })
}

export const trackMenuOpened = menu => {
  trackingClient.trackMenuOpened({
    ...getMandatoryProperties(),
    menu,
  })
}

export const trackItemClicked = (trackingData, options = null) => {
  const { link_url: rawLinkUrl } = trackingData
  const link_url = rawLinkUrl ? getLinkUrl(rawLinkUrl) : undefined

  const props = {
    ...getMandatoryProperties(),
    ...trackingData,
    link_url,
  }

  if (options) {
    trackingClient.trackItemClicked(props, options)
    return
  }

  trackingClient.trackItemClicked(props)
}

export const trackSubmenuItemClick = ({ label, linkUrl, ...otherProps }) => {
  trackItemClicked({
    item: 'submenu',
    item_type: 'menu',
    label: snakeCase(label),
    link_url: linkUrl,
    page: getPage(),
    ...otherProps,
  })
}

export const trackMenuItemClick = ({ label, linkUrl, ...otherProps }) => {
  trackItemClicked({
    item: 'mainmenu',
    item_type: 'menu',
    label: snakeCase(label),
    link_url: linkUrl,
    page: getPage(),
    ...otherProps,
  })
}

/**
 * This is going to be called to track when the enterprise
 * banner becomes entirely visible in the screen
 */
export function trackEnterpriseBannerVisible() {
  trackingClient.trackMessageShown({
    ...getMandatoryProperties(),
    message: 'enterprise_banner',
    page: getPage(),
    location: 'sales_banner:select_plan',
  })
}

/**
 * This is going to be called to track when the button
 * link has been clicked on the enterprise banner
 */
export function trackEnterpriseBannerCta({ pricingVersion, location } = {}) {
  trackItemClicked({
    item: 'contact_sales',
    item_type: 'button',
    page: getPage(),
    location,
    pricing_version: pricingVersion,
  })
}

/**
 * This is going to be called to track when the upgrade
 * plan button has been clicked on the pricing page
 */
export function trackUpgradeCta({ plan, location, pricingVersion }) {
  if (!plan) {
    throw new Error('trackUpgradeCta: missing required property `plan`.')
  }

  if (!location) {
    throw new Error('trackUpgradeCta: missing required property `location`.')
  }

  trackItemClicked({
    item: 'plan_selector',
    item_type: 'button',
    plan_code: plan,
    plan_name: getPlanNameFromPlanCode(plan),
    page: getPage(),
    location,
    pricing_version: pricingVersion,
  })
}

/**
 * This is going to be called to track when the FAQs are toggled on the pricing page
 */
export function trackPricingFAQ({ pricingVersion, label }) {
  trackItemClicked({
    item: 'basic_faq',
    page: getPage(),
    label,
    pricing_version: pricingVersion,
  })
}

/**
 * This is going to be called to track when the billing
 * cycle switch has been clicked on the pricing page
 */
export function trackClickBillingCycle({ period, location, pricing_version }) {
  if (!period) {
    throw new Error(
      'trackClickBillingCycle: missing required property `period`.'
    )
  }

  if (!location) {
    throw new Error(
      'trackClickBillingCycle: missing required property `location`.'
    )
  }

  trackItemClicked({
    item: 'billing_cycle_selector',
    item_type: 'link',
    period,
    page: getPage(),
    location,
    pricing_version,
  })
}

/**
 * This is going to be called to track when the
 * compare plans section is visible in the screen
 */
export function trackPricingScroll({ location, pricingVersion }) {
  if (!location) {
    throw new Error('trackPricingScroll: missing required property `location`.')
  }

  trackEvent('page_navigated', {
    item: 'scroll',
    item_type: 'button',
    value: 'down',
    location,
    pricing_version: pricingVersion,
  })
}

export function trackPricingPlanTooltipHover({
  featureId,
  pricingVersion,
  location,
} = {}) {
  trackItemClicked({
    item: 'tooltip_features',
    item_type: 'hover',
    label: featureId?.split('-').join('_'),
    location,
    pricing_version: pricingVersion,
  })
}

export function trackHomepageSectionInView({ location, scrollPercentage }) {
  if (!location) {
    throw new Error(
      'trackHomepageSectionInView: missing required property `location`.'
    )
  }

  trackEvent('page_navigated', {
    item: 'scroll',
    item_type: 'button',
    value: 'down',
    location,
    location_depth: scrollPercentage,
  })
}

export function trackClickComparePlans({ pricingVersion } = {}) {
  trackingClient.trackItemClicked({
    ...getMandatoryProperties(),
    item: 'compare_plans',
    item_type: 'link',
    page: getPage(),
    location: 'body:compare_plans',
    pricing_version: pricingVersion,
  })
}

export function trackResponsesAddonTooltipShown() {
  trackingClient.sendEventWithCache('message_shown', {
    message: 'responses_addon_tooltip',
    location: 'pricing_page',
    category: 'admin',
  })
}

export function trackSignUp({
  ctaText,
  linkUrl,
  itemType = 'link',
  location,
  pricingVersion,
}) {
  if (!ctaText) {
    throw new Error('trackSignUp: missing required property `ctaText`.')
  }

  if (!linkUrl) {
    throw new Error('trackSignUp: missing required property `linkUrl`.')
  }

  if (!location) {
    throw new Error('trackSignUp: missing required property `location`.')
  }

  trackItemClicked({
    item: 'sign_up',
    item_type: itemType,
    link_url: linkUrl,
    label: snakeCase(ctaText),
    page: getPage(),
    product: 'typeform',
    location,
    pricing_version: pricingVersion,
  })
}

export function trackClickPricingAllFeatures() {
  trackingClient.trackItemClicked({
    item: 'compare_plans',
    item_type: 'link',
    page: getPage(),
    location: 'body:compare_plans',
  })
}

export function trackFooterLinkClick({ key = null, label, href = 'NA' }) {
  if (key === NAVIGATION_LINKS_KEYS.PRICING) {
    trackExperimentEvent({
      name: 'pricing_footer_click',
    })
    trackExperimentEvent({
      name: 'pricing',
    })
  }

  trackEvent(ITEM_CLICKED, {
    item: 'footer',
    item_type: 'menu',
    label: snakeCase(label),
    link_url: href,
  })
}

export function trackArticleVote({ isHelpful, checked }) {
  trackingClient.trackItemClicked({
    ...getMandatoryProperties(),
    item: 'helpful_article',
    item_type: 'button',
    typeform_property: PROPERTY.HELP_CENTER,
    value: isHelpful ? 'yes' : 'no',
    location: getPage(),
    label: checked ? 'check' : 'uncheck',
  })
}

export function trackDeflectedArticleClick({ url, text, email }) {
  trackingClient.trackItemClicked({
    ...getMandatoryProperties(),
    item: 'deflected_article',
    item_type: 'link',
    typeform_property: PROPERTY.HELP_CENTER,
    link_url: url,
    link_url_text: text,
    email,
  })
}

export function trackSignupLanguageSwitcherOptionClick({ locale }) {
  trackingClient.trackItemClicked({
    ...getMandatoryProperties(),
    item: 'language_selection',
    item_type: 'dropdown',
    typeform_property: 'admin_access',
    label: locale === LANGUAGE_CODES.english ? 'english' : 'spanish',
    location: 'header',
    page: 'signup',
  })
}

export function trackLoginButtonOnSignupPageClick({ ctaText }) {
  trackingClient.trackItemClicked({
    ...getMandatoryProperties(),
    item: 'login',
    item_type: 'button',
    typeform_property: 'admin_access',
    label: ctaText,
    location: 'header',
    page: 'signup',
  })
}

export function trackSignupFormError({ label }) {
  trackingClient.trackMessageShown({
    ...getMandatoryProperties(),
    label,
    message: 'signup_error',
    page: 'signup',
    location: 'body',
    typeform_property: 'admin_access',
  })
}

export const trackSignupFormElement = (name, value, formElementTypesMap) => {
  trackItemClicked({
    item: name,
    item_type: formElementTypesMap[name],
    value,
    page: 'signup',
    location: 'body',
    typeform_property: 'admin_access',
  })
}

export const trackSignupOpenConsentsOptions = () => {
  trackItemClicked({
    item: 'open_consents_options',
    item_type: 'button',
    page: 'signup',
    location: 'body',
    typeform_property: 'admin_access',
  })
}

export const trackSignupFormSubmit = () => {
  trackItemClicked({
    item: 'main_signup',
    item_type: 'button',
    page: 'signup',
    location: 'body',
    typeform_property: 'admin_access',
  })
}

export const trackSignupIntentWithEmail = () => {
  trackItemClicked({
    item: 'main_signup_intent',
    item_type: 'button',
    page: 'signup',
    location: 'body',
    typeform_property: 'admin_access',
  })
}

export const trackSocialProviderSignup = (
  provider,
  itemType = 'button',
  useActualPage = false
) => {
  trackItemClicked({
    item: 'social_signup',
    item_type: itemType,
    label: provider,
    page: useActualPage ? getPage() : 'signup',
    location: 'body',
    typeform_property: 'admin_access',
  })
  // Associate signup with affiliate
  trackCjAffiliatesSignup()
}

export const trackSignupImageClick = () => {
  trackItemClicked({
    item: 'media_interaction_attempt',
    item_type: 'background',
    page: 'signup',
    location: 'body',
    typeform_property: 'admin_access',
  })
}

export const trackSignupPageLogoClick = () => {
  trackingClient.trackItemClicked({
    item: 'typeform_logo',
    item_type: 'link',
    page: 'signup',
    location: 'body',
    typeform_property: 'admin_access',
  })
}

export const trackRedirectToPage = ({ label, linkUrl, ...otherProps }) => {
  trackItemClicked({
    item: 'redirect_to_page',
    item_type: 'link',
    label: snakeCase(label),
    link_url: linkUrl,
    page: getPage(),
    ...otherProps,
  })
}
