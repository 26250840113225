import React, { memo, useCallback } from 'react'
import { OptimizelyProvider as BaseOptimizelyProvider } from 'components/optimizely'
import { useRollbar } from '@rollbar/react'
import { trackEvent } from 'components/tracking'
import { getOptimizelyUserId } from 'utils/optimizely'
import useCookieSettings from 'hooks/use-cookie-settings'

export type TExperiment = {
  experimentId: string
  variationId: string
  experimentKey?: string
  variationKey: string
  campaignId?: string
}

const OptimizelyProviderWrapper = ({
  children,
  ...props
}: {
  children: React.ReactNode
}) => {
  const rollbar = useRollbar()
  const { areAdvertisingAndProfilingCookiesAllowed } = useCookieSettings()

  const handleExperimentActive = useCallback(
    ({
      experimentId,
      variationId,
      experimentKey,
      variationKey,
    }: TExperiment) => {
      if (!experimentId || !variationId || !experimentKey || !variationKey) {
        rollbar.warn(
          '[Optimizely] Missing required properties for tracking the experiment_viewed event.',
          {
            experimentId,
            variationId,
            experimentKey,
            variationKey,
          }
        )
        return
      }

      trackEvent('experiment_viewed', {
        experiment_id: experimentId,
        variation_id: variationId,
        experiment_name: experimentKey,
        variation_name: variationKey,
        optimizely_user_id_fingerprint: getOptimizelyUserId(),
      })
    },
    [rollbar]
  )

  const handleExperimentActivationFailed = useCallback(
    ({ experiment, error }: { experiment: TExperiment; error: Error }) => {
      rollbar.error(
        '[Optimizely] Experiment activation failed',
        error,
        experiment
      )
    },
    [rollbar]
  )

  return (
    <BaseOptimizelyProvider
      {...props}
      isTrackingEnabled={areAdvertisingAndProfilingCookiesAllowed}
      onExperimentActive={handleExperimentActive}
      onExperimentActivationFailed={handleExperimentActivationFailed}
    >
      {children}
    </BaseOptimizelyProvider>
  )
}

export default memo(OptimizelyProviderWrapper)
