import { APPEARANCE } from 'constants/design'
import { NAMESPACES } from 'constants/locales'
import { HOMEPAGE_HEADER_SIGN_UP_WITH_FREE_COPY } from 'constants/experiments'

import React, {
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import MegaMenu, {
  MegaMenuType,
  TMenuItemClickHandler,
  TMenuItemClickWithEventObject,
  TSubMenuItemClickEventHandler,
  TUserMenuClickEventObject,
} from 'components/organisms/mega-menu'
import { useOptimizelyContext } from 'components/optimizely/optimizely-provider'
import { snakeCase } from '@s-libs/micro-dash'
import { useUser } from 'hooks/use-user'
import { isExperimentVariationActive } from 'utils/optimizely'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import {
  useBannerContext,
  DisplayType as BannerDisplayType,
} from 'components/molecules/banner-provider'

import {
  trackEvent,
  trackExperimentEvent,
  trackItemClicked,
  trackMenuItemClick,
  trackMenuOpened,
  trackRedirectToPage,
  trackSubmenuItemClick,
} from '../tracking'

import {
  getCtas,
  getUserMenuItems,
  getPrimaryLink,
  flattenMenuItemsWithExperiments,
} from './data'
import { TCtaOnClickFunctionType, THeaderProps } from './types'

export const DEFAULT_HEADER_PROPS = {
  menuVisible: true,
  palette: Palette.Positive,
  transparentBackground: true,
  enableMinimalMenu: false,
  customCta: {},
  trackingConfig: null,
  isVisitorAuthenticated: false,
}

const Header = ({
  trackingConfig,
  palette = Palette.Positive,
  transparentBackground,
  isVisitorAuthenticated,
  customCta,
  enableMinimalMenu,
  appearance,
  topMenu,
}: THeaderProps) => {
  const hasExperimentsActivatedRef = useRef(false)
  const { t } = useTranslation(NAMESPACES.COMMON)
  const router = useRouter()
  const { experiments, activateExperiment } = useOptimizelyContext()
  const { user, loading: isFetchingUser } = useUser()

  const { typeform_property, pricing_version } =
    trackingConfig?.viewPageSectionProps || {}

  const handleSubMenuItemClick: TSubMenuItemClickEventHandler = useCallback(
    ({ item }) => {
      const { label, link, trackingName } = item

      trackSubmenuItemClick({
        label,
        linkUrl: link,
        ...(typeform_property && { typeform_property }),
      })

      if (trackingName) {
        trackExperimentEvent({
          name: trackingName,
        })
      }
    },
    [typeform_property]
  )

  const handleMenuItemClick: TMenuItemClickHandler = useCallback(
    ({ label, link, trackingName }) => {
      trackMenuItemClick({
        label,
        linkUrl: link,
        ...(typeform_property && { typeform_property }),
      })

      if (trackingName) {
        trackExperimentEvent({
          name: trackingName,
        })
      }
    },
    [typeform_property]
  )

  const handleUserMenuClick = useCallback(
    ({ dropdownState }: TUserMenuClickEventObject) => {
      if (dropdownState) {
        trackMenuOpened('user_logged_in')
      }
    },
    []
  )

  const handleUserMenuItemClick = useCallback(
    (props: TMenuItemClickWithEventObject) => {
      const { item } = props

      if (item.id === 'user_menu_dropdown_logout') {
        trackItemClicked({
          item: 'logout',
          item_type: 'menu',
          location: 'user_logged_in_menu',
        })
        return
      }
      trackRedirectToPage({
        item_type: 'menu',
        label: item.trackingName || item.label,
        location: 'user_logged_in_menu',
        linkUrl: item.link,
      })
    },
    []
  )

  const handleLogoClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>): void => {
      trackEvent('item_clicked', {
        item: 'typeform_logo',
        item_type: 'button',
        link_url: event.currentTarget.href,
        location: 'header',
      })
    },
    []
  )

  const handleSignUp: TCtaOnClickFunctionType = useCallback(
    (event, params) => {
      const { menuType } = params || {}
      const castedEvent = event as MouseEvent<HTMLAnchorElement>
      customCta?.tracking
        ? trackItemClicked(customCta.tracking)
        : trackItemClicked({
            item: 'sign_up',
            item_type: 'button',
            link_url: castedEvent.currentTarget.href,
            location:
              menuType === MegaMenuType.Desktop ? 'header_menu' : 'mainmenu',
            ...(typeform_property && { typeform_property }),
            ...(pricing_version && { pricing_version }),
          })

      trackExperimentEvent({ name: 'signup_menu_click' })
    },
    [customCta, typeform_property, pricing_version]
  )

  const handleSignIn: TCtaOnClickFunctionType = useCallback(
    (event, params) => {
      const { menuType } = params || {}
      const castedEvent = event as MouseEvent<HTMLAnchorElement>

      trackItemClicked({
        item: 'login',
        item_type: 'button',
        link_url: castedEvent.currentTarget.href,
        label: snakeCase(castedEvent.currentTarget.textContent || ''),
        location: menuType === MegaMenuType.Desktop ? 'header' : 'mainmenu',
        ...(typeform_property && { typeform_property }),
        ...(pricing_version && { pricing_version }),
      })

      trackExperimentEvent({
        name: 'login_menu_click',
      })
    },
    [typeform_property, pricing_version]
  )

  const computedCustomCta = useMemo(() => {
    if (
      isExperimentVariationActive({
        experiments,
        experimentId: HOMEPAGE_HEADER_SIGN_UP_WITH_FREE_COPY.ID,
        variationId:
          HOMEPAGE_HEADER_SIGN_UP_WITH_FREE_COPY.VARIATIONS.VARIATION,
      })
    ) {
      return {
        label: 'Sign up free',
      }
    }

    return customCta
  }, [experiments, customCta])

  const ctas = useMemo(
    () =>
      getCtas({
        t,
        onSignIn: handleSignIn,
        onSignUp: handleSignUp,
        customCta: computedCustomCta,
        queryParamsToPreserve: router.query,
        isMinimalMenu: enableMinimalMenu,
      }),
    [
      t,
      handleSignIn,
      handleSignUp,
      computedCustomCta,
      enableMinimalMenu,
      router.query,
    ]
  )

  const highlightedMobileLink = useMemo(() => {
    return getPrimaryLink({
      t,
      onSignUp: handleSignUp,
      customCta: computedCustomCta,
      queryParamsToPreserve: router.query,
    })
  }, [computedCustomCta, handleSignUp, router.query, t])

  const userMenuItems = useMemo(() => {
    if (isVisitorAuthenticated || isFetchingUser) {
      return getUserMenuItems(t)
    }

    return undefined
  }, [t, isVisitorAuthenticated, isFetchingUser])

  const [menuItems, experimentsToActivate] = useMemo(() => {
    if (!topMenu) {
      return []
    }

    const [{ items }, experimentsToActivate] =
      flattenMenuItemsWithExperiments(topMenu)

    return [items, experimentsToActivate]
  }, [topMenu])

  useEffect(() => {
    if (
      hasExperimentsActivatedRef.current ||
      !experimentsToActivate?.size ||
      !activateExperiment
    ) {
      return
    }

    hasExperimentsActivatedRef.current = true
    experimentsToActivate.forEach(experiment => {
      const { id, ...otherExperimentProps } = experiment

      activateExperiment({
        experimentId: id,
        ...otherExperimentProps,
      })
    })
  }, [activateExperiment, experimentsToActivate])

  const { bannerHeight, activeBanner } = useBannerContext()

  const activeBannerIsFixed =
    activeBanner?.displayType === BannerDisplayType.Fixed

  const hasDarkAppearance = appearance === APPEARANCE.DARK

  return (
    <MegaMenu
      bannerHeight={bannerHeight}
      bannerIsFixed={activeBannerIsFixed}
      transparentBackground={hasDarkAppearance ? false : transparentBackground}
      palette={hasDarkAppearance ? Palette.Negative : palette}
      mobileMenuTriggerOpenLabel={t('megamenu.mobile.button.open')}
      mobileMenuTriggerCloseLabel={t('megamenu.mobile.button.close')}
      logoTitle={t('public-header.logo.title')}
      logoHref={t('public-header.logo.href')}
      menuItems={menuItems}
      userMenuItems={userMenuItems}
      isFetchingUser={isFetchingUser}
      user={user}
      ctas={ctas}
      highlightedMobileLink={highlightedMobileLink}
      onLogoClick={handleLogoClick}
      onMenuItemClick={handleMenuItemClick}
      onSubMenuItemClick={handleSubMenuItemClick}
      onUserMenuClick={handleUserMenuClick}
      onUserMenuItemClick={handleUserMenuItemClick}
      isMinimalMenu={enableMinimalMenu}
    />
  )
}

Header.defaultProps = DEFAULT_HEADER_PROPS

Header.displayName = 'Header'

export default memo(Header)
