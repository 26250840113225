import { NAMESPACES, DEFAULT_NAMESPACE } from 'constants/locales'

const NAMESPACE_LOADERS = {
  [NAMESPACES.CONTACT_FORM_ZENDESK]: async (_, lang) => {
    const zendesk = await import(`services/zendesk/common`).then(m => m.default)

    return zendesk
      .fetchDynamicContent()
      .then(translations => translations[lang])
  },
  [NAMESPACES.GINGER]: (_, lang) =>
    import(`@typeform/ginger/translations/${lang}`).then(m => m.default),

  default: (namespace, lang) =>
    import(`../translations/${lang}/${namespace}`).then(m => m.default),
}

export const loadLocaleFrom = async (lang, namespace) => {
  const namespaceLoader =
    NAMESPACE_LOADERS[namespace] || NAMESPACE_LOADERS.default

  const namespaceTranslations = await namespaceLoader(namespace, lang)

  if (namespace === DEFAULT_NAMESPACE) {
    return namespaceTranslations
  }

  const commonNamespaceLoader =
    NAMESPACE_LOADERS[DEFAULT_NAMESPACE] || NAMESPACE_LOADERS.default
  const commonNamespaceTranslations = await commonNamespaceLoader(
    DEFAULT_NAMESPACE,
    lang
  )

  return {
    ...commonNamespaceTranslations,
    ...namespaceTranslations,
  }
}
