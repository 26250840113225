import {
  IMAGE_LOADING,
  IMAGE_DECODING,
  IMAGE_FETCHPRIORITY,
} from '@typeform/ginger/dist/constants/image'
import styled from 'styled-components'

import { TLazyAttributeProps } from './types'

// Our current version of the `styled-components` (">= 5") is filtering
// custom and experimental attributes passed down to HTMLElement — e.g., `fetchpriority`.

// TODO: This component should be remove once `styled-components` supports `fetchpriority`
// or if `fetchpriority` is deprecated.

// Our current version of the `styled-components` (">= 5") is filtering
// custom and experimental attributes passed down to HTMLElement — e.g., `fetchpriority`.

// TODO: This component should be remove once `styled-components` supports `fetchpriority`
// or if `fetchpriority` is deprecated.

export const ImageWithLazyAttributes = styled.img
  .withConfig({
    /* stylelint-disable-next-line */
    shouldForwardProp: (prop, defaultValidatorFn) =>
      defaultValidatorFn(prop) || ['fetchpriority'].includes(prop),
  })
  .attrs(({ loading, decoding, fetchpriority }: TLazyAttributeProps) => ({
    loading: loading || IMAGE_LOADING.lazy,
    decoding: decoding || IMAGE_DECODING.async,
    fetchpriority: fetchpriority || IMAGE_FETCHPRIORITY.low,
  }))``
