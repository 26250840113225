import { Palette } from '@typeform/ginger/dist/constants/palettes'
import { AnchorHTMLAttributes, ReactNode } from 'react'

export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

export type TButtonProps = AnchorHTMLAttributes<
  HTMLAnchorElement | HTMLButtonElement
> & {
  children: ReactNode
  palette: Palette
  size: ButtonSize
  fullWidth?: boolean
  hasMinWidth?: boolean
  helperText?: string
  variant: ButtonVariant
  href?: string
}

export type TButtonWrapperProps = {
  fullWidth?: boolean
  variant: ButtonVariant
  hasMinWidth?: boolean
  size: ButtonSize
}

export type TButtonElementProps = {
  size: ButtonSize
  palette: Palette
  variant: ButtonVariant
}
