import TypeformLogo, { TLogoProps } from 'components/atoms/common/logo'

import { useMenuContext } from '../../mega-menu-provider'

import { TypeformLogoWrapper } from './logo.styles'

const Logo = (props: TLogoProps) => {
  const { isMenuScrolled } = useMenuContext()

  return (
    <TypeformLogoWrapper>
      <TypeformLogo
        data-testid='static-logo'
        {...props}
        animated
        hideType={isMenuScrolled}
      />
    </TypeformLogoWrapper>
  )
}

export default Logo
