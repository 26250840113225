import React, {
  useContext,
  memo,
  useMemo,
  useRef,
  useCallback,
  useEffect,
  useState,
} from 'react'
import cookies from 'utils/cookies'
// @ts-expect-error services/optimizely-service not types yet
import OptimizelyService from '@typeform/ginger/dist/services/optimizely-service'
import { TExperiment } from 'components/optimizely-provider-wrapper/optimizely-provider-wrapper'

type TOptimizelyProvider = {
  sdkKey?: string
  isTrackingEnabled?: boolean
  experiments?: { [key: string]: TExperiment }
  children: React.ReactNode | React.ReactNode[]
  attributes?: []
  onExperimentActive?: (experiment: TExperiment) => void
  onExperimentActivationFailed?: ({
    experiment,
    error,
  }: {
    experiment: TExperiment
    error: Error
  }) => void
}

type TOptimizelyContext = {
  experiments?: { [key: string]: TExperiment }
  userId?: null | string
  experimentsToActivate?: { [key: string]: TExperiment }
  activateExperiment: (experiment: TExperiment) => void
  trackEvent: (eventId: string) => void
  attributes: []
}

const OptimizelyContext = React.createContext<TOptimizelyContext>({
  experiments: {},
  userId: null,
  activateExperiment: () => {},
  trackEvent: () => {},
  attributes: [],
})

export const OPTIMIZELY_USER_COOKIE_ID = 'experiments-fingerprint'
export const OPTIMIZELY_EXPERIMENTS_COOKIE_ID = 'experiments-raw'

const useOptimizelyContext = () => useContext(OptimizelyContext)

const OptimizelyProvider = ({
  children,
  experiments,
  isTrackingEnabled = false,
  attributes = [],
  onExperimentActive = () => {},
  onExperimentActivationFailed = () => {},
}: TOptimizelyProvider) => {
  const optimizelyCallstack = useRef<((id: string) => void)[]>([])
  const [userId, setUserId] = useState<string | null>()
  const [experimentsToActivate, setExperimentsToActivate] = useState()
  const activatedExperiments = useRef<string[]>([])

  if (!experiments) {
    throw new Error('OptimizelyProvider: experiments not provided.')
  }

  const trackActivation = useCallback(
    ({ experiment, id }: { experiment: TExperiment; id: string }) => {
      const { campaignId, experimentId, variationId } = experiment

      if (activatedExperiments.current.includes(experimentId)) {
        return
      }

      activatedExperiments.current.push(experimentId)

      OptimizelyService.activateExperiment({
        userId: id,
        campaignId,
        experimentId,
        variationId,
        attributes,
      }).then(({ ok, error }: { ok: boolean; error: Error }) => {
        ok
          ? onExperimentActive(experiment)
          : onExperimentActivationFailed({ experiment, error })
      })
    },
    [attributes, onExperimentActivationFailed, onExperimentActive]
  )

  const activateExperiment = useCallback(
    (experiment: TExperiment) => {
      const { campaignId, experimentId, variationId } = experiment

      if (campaignId && experimentId && variationId) {
        if (isTrackingEnabled && userId) {
          trackActivation({
            experiment,
            id: userId,
          })
        } else {
          const { current: callstack } = optimizelyCallstack

          callstack.push((id: string) => {
            trackActivation({
              experiment,
              id,
            })
          })
        }
      }
    },
    [isTrackingEnabled, userId, trackActivation]
  )

  const trackEvent = useCallback(
    (eventId: string) => {
      if (isTrackingEnabled && userId) {
        OptimizelyService.trackEvent({
          userId,
          eventId,
          attributes,
        })
      } else {
        const { current: callstack } = optimizelyCallstack

        callstack.push(id =>
          OptimizelyService.trackEvent({
            userId: id,
            eventId,
            attributes,
          })
        )
      }
    },
    [isTrackingEnabled, userId, attributes]
  )

  /* Manages the user ID storage */
  useEffect(() => {
    if (userId === undefined) {
      const cuid = cookies.get(OPTIMIZELY_USER_COOKIE_ID)
      setUserId(cuid || null)
    }
  }, [userId])

  /* Manages the experiments to activate storage */
  useEffect(() => {
    if (experimentsToActivate === undefined) {
      const experimentsRaw = cookies.get(OPTIMIZELY_EXPERIMENTS_COOKIE_ID)

      setExperimentsToActivate(
        experimentsRaw
          ? OptimizelyService.normaliseExperiments(experimentsRaw)
          : null
      )
    }
  }, [experimentsToActivate])

  /* Flushes the callstack client-side when the tracking service is enabled and the user ID available */
  useEffect(() => {
    if (isTrackingEnabled && userId) {
      if (optimizelyCallstack.current.length > 0) {
        optimizelyCallstack.current.forEach(call => call(userId))
        optimizelyCallstack.current = []
      }
    }
  }, [isTrackingEnabled, userId])

  const providerValue = useMemo(
    () => ({
      experiments,
      userId,
      experimentsToActivate,
      activateExperiment,
      trackEvent,
      attributes,
    }),
    [
      activateExperiment,
      experiments,
      experimentsToActivate,
      trackEvent,
      userId,
      attributes,
    ]
  )

  return (
    <OptimizelyContext.Provider value={providerValue}>
      {children}
    </OptimizelyContext.Provider>
  )
}

OptimizelyProvider.displayName = 'OptimizelyProvider'

const MemoOptimizelyProvider = memo(OptimizelyProvider)

export {
  OptimizelyContext,
  useOptimizelyContext,
  MemoOptimizelyProvider as OptimizelyProvider,
}
